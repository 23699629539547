@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@font-face {
  font-family: firagomedium;
  src: url(./fonts/FiraGO-Medium.ttf);
}
@font-face {
  font-family: bigtext;
  src: url(./fonts/Mitr-Bold.ttf);
}
@font-face {
  font-family: bignoodle;
  src: url(./fonts/big_noodle_titling.ttf);
}
@font-face {
  font-family: datebashvili;
  src: url(./fonts/datebashvili.woff2) format("woff2"), url(./fonts/datebashvili.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: poppinslight;
  src: url(./fonts/poppins.ttf);
}
@font-face {
  font-family: segoe;
  src: url(./fonts/days.ttf);
};
.app {
  width: 100%;
  height: 100%;
  cursor: none ;
  position: relative;
}
a {
	cursor: none !important;
	text-decoration: none;
}
li{
	list-style-type: none;
	display: block;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0;
	font-weight: normal; 
  font-size: 1em;
}
.container{
  width: 85%;
  margin: auto;
  cursor: none ;
}
.header{
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_cont{
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}
.header_logo{
  background-image: url(./images/logo.svg);
  width: 200px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.header_nav{
  font-family: firagomedium;
  font-feature-settings: "case" on;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;
  position: absolute;
  left: 0px;
  white-space: nowrap; 
}
.header_nav_item_container{
  margin-left: 100px;
  margin-top: 10px;
}
.header_nav_item{
  transition: 0.4s;
}
.header_nav_item:hover ~ .header_nav_item_decline {
  width: 99%;
  transition: 0.4s;
  opacity: 1;
}
.header_nav_item:hover{
  color: #c9880f;
  transition: 0.4s;
}
.header_nav_item_decline{
  width: 0;
  height: 4px;
  border-radius: 50px;
  background: #c9880f;
  transition: 0.4s;
  margin: auto;
  opacity: 0;
  margin-top: 10px;
}
.header_langs{
  display: flex;
  font-size: 37px;
  color: white;
  position: absolute;
  right: 0px;
  font-family: bignoodle;
  white-space: nowrap; 
}
.header_language{
  margin: 10px;
  transition: 0.4s;
}
.header_language:hover{
  color: #c9880f;
  transition: 0.4s;
}
.firstpage{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  /* padding-top: 100px;
  padding-bottom: 100px; */
  padding-top: 5%;
  padding-bottom: 5%; 

}
.firstpage_left_title{
  font-size: 85px;
  color: white;
  font-family: datebashvili;
  max-width: 500px;
}
.firstpage_right{
  margin-left: 100px;
  width: 789px;
  height: 717px;
  background-image: url(./images/test.png);
  background-size: 99%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: -50px;
  
}
.firstpage_left{

}
.firstpage_left_desc{
  font-size: 27px;
  color: white;
  font-family: firagomedium;
  font-feature-settings: "case" on;
  margin-top: 30px;
}
.firstpage_left_bigdesc{
  color: rgba(255, 255, 255, 0.422);
  max-width: 650px;
  margin-top: 30px;
  font-size: 20px;
  font-family: firagomedium;
  font-feature-settings: "case" on;
}
.firstpage_left_button_cont{
  display: flex;
  margin-top: 50px;
  align-items: center;
}
.firstpage_left_order_button{
  display: block;
  width: 250px;
  height: 60px;
  border-radius: 10px;
  background: rgb(239,173,44);
  background: radial-gradient(circle, rgba(239,173,44,1) 0%, rgba(239,168,44,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: datebashvili;
  font-size: 18px;
  transition: 0.4s;
}
.firstpage_left_order_button:hover{
  transition: 0.4s;
  -webkit-box-shadow: -1px 3px 38px -9px rgba(239, 174, 44, 0.751);
-moz-box-shadow: -1px 3px 38px -9px rgba(239, 174, 44, 0.751);
box-shadow: -1px 3px 38px -9px rgba(239, 174, 44, 0.751);
}
.firstpage_left_order_video{
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.firstpage_left_order_video:hover .firstpage_left_video_icon{
  transform: rotate(90deg);
  transition: 0.4s;
  background-image: url(./images/play_h.png);
}
.firstpage_left_order_video:hover .firstpage_left_video_text{
  color: rgb(239,173,44);
  transition: 0.4s;
}
.firstpage_left_video_icon{
  width: 50px;
  height: 50px;
  background-image: url(./images/play.png);
  background-size: 99%;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.4s;
}
.firstpage_left_video_text{
  font-family: datebashvili;
  font-size: 20px;
  color: white;
  margin-left: 15px;
  transition: 0.4s;
}
.firstpage_socials_insta{
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(./images/Instagram.png);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  transition: 0.4s;
}
.firstpage_socials_insta:hover{
  background-image: url(./images/Instagram_h.png); 
  transition: 0.4s;
}
.firstpage_socials_fb{
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(./images/Facebook.png);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s;
}
.firstpage_socials_fb:hover{
  transition: 0.4s;
  background-image: url(./images/Facebook_h.png);
}
.firstpage_socials_phone{
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(./images/Whatsapp.png);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 10px;
  transition: 0.4s;
}
.firstpage_socials_phone:hover{
  transition: 0.4s;
  background-image: url(./images/Whatsapp_h.png);
}
.firstpage_socials{
  position: absolute;
  left: -100px;
  margin-top: -106px;
}
.firstpage_left_option_cont{
  display: flex;
  align-items: center;
  opacity: 1;
  margin-top: 20px;
  background-color: rgba(40,40,40);
  border-radius: 5px;
  padding: 5px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  transition: 0.4s;
}
.firstpage_left_option_icon{
  width: 13px;
  height: 13px;
  background: rgb(239,173,44);
  border-radius: 50px;
  margin-right: 15px;
}
.firstpage_left_option_text{
  font-family: firagomedium;
  font-feature-settings: "case" on;
  color: white;
}
.firstpage_left_option_quantity{
  font-family: firagomedium;
  font-feature-settings: "case" on;
  color: white;
  width: 75px;
  height: 25px;
  border-radius: 5px;
  background: rgb(239,173,44);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 13px;
}

.secondpage{
  width: 100%;
  /* padding-top: 100px;
  padding-bottom: 100px; */
  padding-top: 7%;
  padding-bottom: 7%; 
  position: relative;
  overflow: hidden !important;
  margin-top: 50px;
}
.secondpage_dec_text{
  white-space: nowrap; 
   /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.729);  */
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.729);
  font-family: datebashvili;
  color: rgba(255, 255, 255, 0);
  font-size:8.21vw;
  opacity: 0.07;
}
.secondpage_container{
  width: 100%;
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  overflow: hidden !important;
  padding-bottom: 120px;
}
.secondpage_container_item1 , .secondpage_container_item2 , .secondpage_container_item3{
  width: 515px;
  height: 800px;
  border: 2px solid rgba(255, 255, 255, 0.603);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.secondpage_container_item1:hover , .secondpage_container_item2:hover , .secondpage_container_item3:hover {
  transition: 0.4s;
  border: 2px solid rgba(239, 174, 44, 0.601);
  background: rgba(239, 174, 44, 0.078);
  
}
.secondpage_container_item1:hover  .firstpage_left_option_cont{
  background: rgba(239, 174, 44, 0.199);
  transition: 0.4s;
}
.secondpage_container_item_image1{
  width: 260px;
  height: 260px;
  background-image: url(./images/test2.png);
  background-size: 99%;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  
}
.secondpage_container_item_title{
  font-size: 33px;
  font-family: datebashvili;
  color: white;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.secondpage_container_item_desc{
  font-size: 19px;
  color: white;
  font-family: firagomedium;
  font-feature-settings: "case" on;
  max-width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 30px;
}
.secondpage_container_item_button{
  width: 260px;
  height: 50px;
  background: rgb(239,173,44);
  background: radial-gradient(circle, rgba(239,173,44,1) 0%, rgba(239,168,44,1) 100%);
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: datebashvili;
  font-size: 18px;
  margin-top: 50px;
}
.secondpage_container_item_button:hover{
  -webkit-box-shadow: -1px 3px 38px -9px rgba(239, 174, 44, 0.751);
  -moz-box-shadow: -1px 3px 38px -9px rgba(239, 174, 44, 0.751);
  box-shadow: -1px 3px 38px -9px rgba(239, 174, 44, 0.751);
  font-size: 0;
}
.secondpage_container_item_button:hover:before {
  content: attr(data-hover);
  font-size: 18px;
}
.btnact{
  width: 100% !important;
  height: 150px;
  transition: 0.4s;
  width: 260px;
  background: rgb(239,173,44);
  background: radial-gradient(circle, rgba(239,173,44,1) 0%, rgba(239,168,44,1) 100%);
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: datebashvili;
  font-size: 18px;
  margin-top: 50px;
}
.heigfix{
  height: 850px !important;
  transition: 0.4s;
}
.secondpage_item_container1 , .secondpage_item_container2 , .secondpage_item_container3{
  margin-left: 4%;
  margin-right: 4%;
  width: 520px;
  height: 800px;
  transition: 0.4s;
}
.secondpage_item_container1 {
  margin-left: 0px !important;
  position: absolute;
  left: 0px;
}
.secondpage_item_container2{
  opacity: 0.5;
  pointer-events: none;
}
.secondpage_item_container3 {
  margin-right: 0px !important;
  position: absolute;
  right: 0px;
  opacity: 0.5;
  pointer-events: none;
}
.secondpage_item_warning{
  font-size: 13px;
  color: rgba(255, 255, 255, 0.401);
  font-family: firagomedium;
  font-feature-settings: "case" on;
  width: 60%;
  text-align: center;
  margin: auto;
  margin-top: 15px;
  font-weight: 200;
}

.thirdpage{
  width: 100%;
  /* padding-top: 100px;
  padding-bottom: 50px; */
  padding-top: 7%;
  padding-bottom: 7%; 
  position: relative;

}
.thirdpage_container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}
.thirdpage_dec_text{

  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #efa72c16; */
  -webkit-text-stroke: 1px #efa72c;
  font-family: datebashvili;
  color: rgba(255, 255, 255, 0);
  font-size:18vw;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Стандартный синтаксис */
  opacity: 0.07;
}
.thirdpage_left{
  position: absolute;
  left: 0px;
}
.secondpage_container_item_container{
  display: block;
}
.thirdpage_left_title{
  color: white;
  font-size: 60px;
  font-family: datebashvili;
  z-index: 5;
}
.thirdpage_left_desc{
  color: white;
  font-size: 37px;
  font-family: firagomedium;
  font-feature-settings: "case" on;
  margin-top: 50px;
  max-width: 830px;
  z-index: 5;
}
.thirdpage_left_warning{
  color: rgb(239,173,44);
  font-size: 20px;
  font-family: firagomedium;
  font-feature-settings: "case" on;
  margin-top: 50px;
  z-index: 5;
}
.thirdpage_right{
  width: 630px;
  height: 900px;
  background-image: url(./images/chika.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 99%;
  z-index: 5;
  position: absolute;
  right: 0px;
}
.footer{
  margin-top: 200px;
  width: 100%;
  height: 230px;
  background-color: #1d1d1d;
  border-top: 1px solid #c9880f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_container{
  width: 85%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-family: firagomedium;
  font-feature-settings: "case" on;
  position: relative;
}
.footer_title_2{
  margin-top: 5px;
}
.footer_left_cont{
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  zoom: 110%;
}
.footer_pages{
  margin-left: 8vw;
}
.footer_page{
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer_page_icon{
  width: 15px;
  height: 15px;
  background: rgb(239, 173, 44);
  border-radius: 50px;
}
.footer_page_text{
  font-family: firagomedium;
  font-feature-settings: "case" on;
  margin-left: 10px;
  margin-top: 2px;
  transition: 0.4s;
  font-weight: 200;
}
.footer_title_cont{
  font-weight: 200;
}
.footer_page:hover .footer_page_text{
  transition: 0.4s;
  color: rgb(239, 173, 44);
}
.footer_page_icon1{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(./images/Instagram.png);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s;
}
.footer_page_icon1:hover{
  background-image: url(./images/Instagram_h.png); 
  transition: 0.4s;
}
.footer_page_icon2{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(./images/Facebook.png);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s;
}
.footer_page_icon2:hover{
  transition: 0.4s;
  background-image: url(./images/Facebook_h.png);
}
.footer_page_icon3{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(./images/Whatsapp.png);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s;
}
.footer_page_icon3:hover{
  transition: 0.4s;
  background-image: url(./images/Whatsapp_h.png);
}
.footer_back{
  position: absolute;
  right: 0px;
  background-image: url(./images/up.png);
  background-size: 99%;
  background-position: center;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  transition: 0.4s;
  zoom: 110%;
}
.footer_back:hover{
  background-image: url(./images/up_h.png);
  transition: 0.4s;
}
.order_active_title{
  font-size: 14px ;
  color: #524222;
  text-align: center;
  transition: 0.4s;
  font-weight: 200;
}
.order_active_warning{
  font-size: 10px ;
  max-width: 350px;
  text-align: center;
  color: #524222;
  transition: 0.4s;
  line-height: 15px;
  font-weight: 200;
}
.order_active_buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.order_active_button1, .order_active_button2, .order_active_button3{
  width: 60px;
  height: 60px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.307);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: 0.4s;
}
.order_active_button1{
  background-image: url(./images/Instagram_h.png);
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.order_active_button1:hover, .order_active_button2:hover, .order_active_button3:hover{
background-color: rgba(0, 0, 0, 0.744);
}
.order_active_button2{
  background-image: url(./images/Facebook_h.png);
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.order_active_button3{
  background-image: url(./images/Whatsapp_h.png);
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.order_cont{
  zoom: 80%;
}

@media only screen and (max-width: 3840px) {
	/* 3840x2160 */

  .header{
  zoom: 200%;
  }
  /* .firstpage{
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .secondpage{
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .thirdpage{
    padding-top: 200px;
    padding-bottom: 200px;
  } */
  .firstpage_left{
    zoom: 200%; 
  }
  .firstpage_right{
    zoom: 200%; 
  }
  .firstpage_socials{
    zoom: 200%; 
  }
  .secondpage_container{
    zoom: 200%; 
  }
  .thirdpage_left{
    zoom: 200%;
  }
  .thirdpage_right{
    zoom: 200%;
  }

  .footer{
    zoom: 200%;
  }

}

@media only screen and (max-width: 2560px) {
	/* 2560x1440 , 2560x1600 */

  .header{
    zoom: 130%;
  }
  /* .firstpage{
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .secondpage{
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .thirdpage{
    padding-top: 150px;
    padding-bottom: 150px;
  } */
  .firstpage_left{
    zoom: 130%;
  }
  .firstpage_right{
    zoom: 130%;
  }
  .firstpage_socials{
    zoom: 130%;
  }
  .secondpage_container{
    zoom: 130%;
  }
  .thirdpage_left{
    zoom: 130%;
  }
  .thirdpage_right{
    zoom: 130%;
  }
  .footer{
    zoom: 130%;
  } 
  
}

@media only screen and (max-width: 2100px) {

  .header{
    zoom: 100%;
  }
  /* .firstpage{
    padding-top: 70px;
    padding-top: 70px;
  }
  .secondpage{
    padding-top: 70px;
    padding-top: 70px;
  }
  .thirdpage{
    padding-top: 70px;
    padding-top: 70px;
  } */
  .firstpage_left{
    zoom: 100%;
  }
  .firstpage_right{
    zoom: 100%;
  }
  .firstpage_socials{
    zoom: 100%;
  }
  .secondpage_item_container1 ,.secondpage_item_container2 ,.secondpage_item_container3{
    zoom: 93%;
  }
  .secondpage_container{
    zoom: 100%;
  }
  .thirdpage_left{
    zoom: 100%;
  }
  .thirdpage_right{
    zoom: 100%;
  }
  .footer{
    zoom: 100%;
  } 

}

@media only screen and (max-width: 1920px) {
	/* 1920x1080 */
  .header{
    zoom: 100%;
  }
  /* .firstpage{
    padding-top: 70px;
    padding-top: 70px;
  }
  .secondpage{
    padding-top: 70px;
    padding-top: 70px;
  }
  .thirdpage{
    padding-top: 70px;
    padding-top: 70px;
  } */
  .firstpage_left{
    zoom: 100%;
  }
  .firstpage_right{
    zoom: 100%;
  }
  .firstpage_socials{
    zoom: 100%;
  }
  .secondpage_container{
    zoom: 100%;
  }
  .thirdpage_left{
    zoom: 100%;
  }
  .thirdpage_right{
    zoom: 100%;
  }
  .footer{
    zoom: 100%;
  } 
}

@media only screen and (max-width: 1600px) {
	/* 1600x900 , 1536x864 */

  .header{
    zoom: 80%;
  }
  .firstpage_left{
    zoom: 80%;
  }
  .firstpage_right{
    zoom: 80%;
  }
  .firstpage_socials{
    zoom: 80%;
  }
  .secondpage_container{
    zoom: 80%;
  }
  .secondpage{
    /* margin-top: 200px;
    height: 70vh; */
  }
  .thirdpage_left{
    zoom: 80%;
  }
  .thirdpage_right{
    zoom: 80%;
  }
  .footer{
    zoom: 80%;
  } 

}

@media only screen and (max-width: 1440px) {
  /* 1440x900 */

  .header{
    zoom: 75%;
  }
  .firstpage_left{
    zoom: 75%;
  }
  .firstpage_right{
    zoom: 75%;
  }
  .firstpage_socials{
    zoom: 75%;
  }
  .secondpage_container{
    zoom: 75%;
  }
  .secondpage{
    /* margin-top: 200px;
    height: 70vh; */
  }
  .thirdpage_left{
    zoom: 75%;
  }
  .thirdpage_right{
    zoom: 75%;
  }
  .footer{
    zoom: 75%;
  } 

}

@media only screen and (max-width: 1380px) {
	/* 1366x768 , 1280x720 */

  .header{
    zoom: 70%;
  }
  .firstpage_left{
    zoom: 70%;
  }
  .firstpage_right{
    zoom: 70%;
  }
  .firstpage_socials{
    zoom: 70%;
  }
  .secondpage_container{
    zoom: 70%;
  }
  .secondpage{
    /* margin-top: 200px;
    height: 70vh; */
  }
  .thirdpage_left{
    zoom: 70%;
  }
  .thirdpage_right{
    zoom: 70%;
  }
  .footer{
    zoom: 70%;
  } 

}

@media only screen and (max-width: 1024px) {
	/* 1024x768 */

  .header{
    zoom: 55%;
  }
  /* .firstpage{
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .secondpage{
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .thirdpage{
    padding-top: 70px;
    padding-bottom: 70px;
  } */
  .firstpage_left{
    zoom: 55%;
  }
  .firstpage_right{
    zoom: 55%;
  }
  .firstpage_socials{
    zoom: 55%;
  }
  .secondpage_container{
    zoom: 55%;
  }
  .thirdpage_left{
    zoom: 55%;
  }
  .thirdpage_right{
    zoom: 55%;
  }
  .footer{
    zoom: 55%;
    margin-top: 100px;
  } 

}
@media only screen and (max-width:1024px) and (min-height: 800px) {
	 /* ipads */

   .header{
    zoom: 55%;
  }
  .firstpage_left{
    zoom: 55%;
  }
  .firstpage_right{
    zoom: 55%;
  }
  .firstpage_socials{
    zoom: 55%;
  }
  .secondpage_container{
    zoom: 55%;
  }
  .thirdpage_left{
    zoom: 55%;
  }
  .thirdpage_right{
    zoom: 55%;
  }
  .footer{
    zoom: 55%;
    margin-top: 100px;
  } 

}
@media only screen and (max-width: 860px) {
	/* 800x600 */

  .header{
    zoom: 40%;
  }
  /* .firstpage{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .secondpage{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .thirdpage{
    padding-top: 50px;
    padding-bottom: 50px;
  } */
  .firstpage_left{
    zoom: 40%;
  }
  .firstpage_right{
    zoom: 40%;
  }
  .firstpage_socials{
    zoom: 40%;
  }
  .secondpage_container{
    zoom: 40%;
  }

  .thirdpage_left{
    zoom: 40%;
  }
  .thirdpage_right{
    zoom: 40%;
  }
  .footer{
    zoom: 40%;
    margin-top: 100px;
  } 

}

@media only screen and (max-width:540px) {
	/* surface Duo */

  .header{
    zoom: 27%;
  }
  .firstpage{
    /* height: 50vh; */
  }
  .firstpage_left_bigdesc{
    max-width: 900px;
    text-align: center;
  }
  .secondpage_dec_text{
    margin-left: 0px !important;
  }
  .thirdpage_dec_text{
    /* font-size: 16vw !important;  */
  }
  .firstpage_left{
    zoom: 27%;
  }
  .firstpage_right{
    zoom: 27%;
    margin-top: 50px;
  }
  .firstpage_socials{
    zoom: 27%;
  }
  .secondpage_container{
    zoom: 27%;
  }
  .secondpage{
    /* margin-top: 50px;
    height: 35vh; */
    padding-bottom: 10%;
  }
  .thirdpage{
    /* margin-top: 0px;
    height: 50vh; */
  }
  .thirdpage_left_desc{
    max-width: 1100px;
  }
  .thirdpage_left{
    zoom: 27%;
  }
  .thirdpage_right{
    zoom: 27%;
  }
  .footer{
    zoom: 40%;
    margin-top: 100px;
  } 

}

@media only screen and (max-width:480px) {

  .header{
    zoom: 50%;
  }
  .header_nav_item_container{
    display: none;
  }
  .firstpage{
    /* height: 50vh; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
  .firstpage_left{
    zoom: 27%;
    position: inherit;
  }
  .firstpage_left_desc{
    text-align: center;
  }
  .firstpage_left_option_quantity{
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
    width: 95px;
  }
  .firstpage_left_option_cont{
    opacity: 1 !important;
  }
  .firstpage_left_option_icon{
    opacity: 0.5 !important;
  }
  .firstpage_left_option_text{
    opacity: 0.5 !important;
  }
  .firstpage_right{
    zoom: 27%;
    position: inherit;
    margin-left: 0px;
    right: 0px;
  }
  .firstpage_left_title{
    text-align: center;
    margin: auto;
  }
  .firstpage_left_option_cont{
    /* justify-content: center; */
  }
  .firstpage_left_button_cont{
    justify-content: center;
  }
  .firstpage_socials{
    zoom: 40%;
  }
  .secondpage_container{
    zoom: 27%;
    flex-wrap: wrap;
  }
  .secondpage_item_container1{
    position: inherit;
    zoom: 180%;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 50px;
    margin-top: 10px !important;
  }
  .secondpage_item_container2{
    zoom: 180%;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 50px;
  }
  .secondpage_item_container3{
    position: inherit;
    zoom: 180%;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 100px;
  }
  .secondpage{
    /* margin-top: 50px;
    height: 55vh; */
    overflow-y: hidden;
  }
  .thirdpage{
    /* margin-top: 0px;
    height: 40vh; */
    overflow-y: hidden;
  }
  .thirdpage_left{
    zoom: 20%;
  }
  .thirdpage_right{
    zoom: 20%;
  }
  .footer{
    zoom: 37%;
    margin-top: 0px;
    overflow-y: hidden;
  } 
  .footer_pages{
    margin-left: 30vw;
  }

}

@media only screen and (max-width:431px) {

  .header{
    zoom: 50%;
  }
  .firstpage{
    /* height: 60vh; */
  }
  .firstpage_left{
    zoom: 28%;
  }
  .firstpage_right{
    zoom: 28%;
  }
  .firstpage_socials{
    zoom: 40%;
  }
  .firstpage_socials{
    left: 0px
  }
  .secondpage_container{
    zoom: 28%;
  }
  .secondpage{
    /* height: 55vh; */
  }
  .thirdpage{
    /* height: 40vh; */
  }
  .thirdpage_left{
    zoom: 20%;
  }
  .thirdpage_right{
    zoom: 20%;
  }
  .footer{
    zoom: 32%;
  } 

}

@media only screen and (max-width:400px) {

  
}


@media only screen and (max-width:380px) {

  .header{
    zoom: 50%;
  }
  .firstpage{
    /* height: 60vh; */
  }
  .firstpage_left{
    zoom: 25%;
  }
  .firstpage_right{
    zoom: 25%;
  }
  .firstpage_socials{
    zoom: 25%;
  }
  .firstpage_socials{
    left: 0px
  }
  .secondpage_container{
    zoom: 25%;
  }
  .secondpage{
    /* height: 55vh; */
  }
  .thirdpage{
    /* height: 55vh; */
  }
  .thirdpage_left{
    zoom: 25%;
  }
  .thirdpage_right{
    zoom: 25%;
  }
  .footer{
    zoom: 35%;
  } 

}

@media only screen and (max-width:340px) {

}

@media only screen and (max-width:300px) {

  .header{
    zoom: 50%;
  }
  .firstpage{
    /* height: 60vh; */
  }
  .firstpage_left{
    zoom: 20%;
  }
  .firstpage_right{
    zoom: 20%;
  }
  .firstpage_socials{
    zoom: 20%;
  }
  .firstpage_socials{
    left: 0px
  }
  .secondpage_container{
    zoom: 20%;
  }
  .secondpage{
    /* height: 45vh; */
  }
  .thirdpage{
    /* height: 45vh; */
  }
  .thirdpage_left{
    zoom: 20%;
  }
  .thirdpage_right{
    zoom: 20%;
  }
  .footer{
    zoom: 32%;
  } 

}
@supports (-webkit-touch-callout: none) { 
  /* iphone only */

  .secondpage_item_warning{
    -webkit-text-size-adjust: 100%;
    font-size: 4px;
  }
  .secondpage_item_container1{
    margin-top: 50px;
  }
  .secondpage_item_container2{
    margin-top: 50px;
  }
  .secondpage_item_container3{
    margin-top: 150px;
  }
  .secondpage_container_item_button{
   zoom: 130%;
  }
  .secondpage_container_item_button:hover:before{

  }
  .firstpage_left_option_quantity{
    -webkit-text-size-adjust: 100%;
    font-size: 12px;
    zoom: 180%;
    padding-left: 2px;
    padding-right: 2px;
    width: 95px;
  }
  .secondpage{
    /* height: 60vh !important; */
  }
  .secondpage_container_item_container{
    zoom: 80%;
  }
  .order_active_title{
    -webkit-text-size-adjust: 100% !important;
    font-size: 6px !important;
  }
  .order_active_warning{
    -webkit-text-size-adjust: 100% !important; 
    max-width: 450px;
    font-size: 4px !important;
    line-height: 20px;
  }
  .secondpage_item_warning{
    -webkit-text-size-adjust: 100%;
    font-size: 8px;
    width: 80%;
  }
  .footer_back{
    zoom: 140%;
  }
  .pgs{
    display: none;
  }
  .footer{
    zoom: 30%;
  } 
  .footer_container{
    zoom: 100%;
  } 
  .footer_pages{
    margin-left: 10vw;
  }
  .thirdpage_left{
    zoom: 17%;
  }
  .thirdpage_right{
    zoom: 17%;
  }
  .header{
    zoom: 50%;
  }
  .firstpage_socials{
    left: 0px
  }
  .thirdpage_left_warning{
    width: 70%;
  }
  .firstpage_left_option_cont{
    opacity: 1 !important;
  }
  .firstpage_left_option_icon{
    opacity: 0.5 !important;
  }
  .firstpage_left_option_text{
    opacity: 0.5 !important;
  }
}
